import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, Typography } from '@mui/material';
import logo from '../assets/AE_Sign_purple.svg';
import burger from '../assets/Burger_purple.svg';
import cross from '../assets/cross.svg';

import { StoryblokComponent, SbBlokData } from '@storyblok/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = ({ config }: any) => {
  const navigate = useNavigate();
  const location = useLocation(); // Utiliser pour obtenir la langue actuelle
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dateTime, setDateTime] = useState('');

  const { t } = useTranslation();

  // Extraire la langue actuelle depuis l'URL
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentLocale = pathSegments[0] === 'en' ? 'en' : 'de'; // "de" par défaut

  // Gérer le changement de langue
  const toggleLanguage = () => {
    const newLocale = currentLocale === 'en' ? 'de' : 'en'; // Basculer entre 'de' et 'en'

    // Si on passe à "de", on supprime "en" de l'URL et on redirige à la racine sans locale
    const newPathname =
      newLocale === 'de'
        ? location.pathname.replace(/^\/en/, '') // Retirer "en" de l'URL pour l'allemand
        : `/en${location.pathname}`; // Ajouter "/en" pour l'anglais

    navigate(newPathname); // Rediriger vers la nouvelle URL
  };

  const handleLogoClick = () => {
    const homePath = currentLocale === 'de' ? '/' : '/en';
    navigate(homePath);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();

      // Déterminer la locale à utiliser
      const locale = currentLocale === 'de' ? 'de-DE' : 'en-US'; // "de-DE" pour allemand, "en-US" pour anglais

      // Déterminer le format de l'heure (ajouter "Uhr" pour l'allemand)
      const timeSuffix = currentLocale === 'de' ? ' Uhr' : ''; // Ajouter "Uhr" en allemand, sinon vide en anglais

      const formattedDateTime = `${now.toLocaleDateString(locale, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })} / ${now.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      })}${timeSuffix}`; // Ajouter le suffixe pour l'allemand
      setDateTime(formattedDateTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup
  }, [currentLocale]);

  return (
    <AppBar position='fixed' elevation={0} sx={{ backgroundColor: '#FFFFFF' }}>
      <div
        className='tablet:container tablet:mx-auto max-w-container'
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Toolbar
          className='flex justify-between py-[10px] tablet:py-[24px]'
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <div className='flex items-center flex-nowrap space-x-4 gap-2  phone:gap-10 h-auto'>
            <button
              onClick={handleLogoClick}
              style={{
                border: 'none',
                background: 'none',
                padding: 0,
                cursor: 'pointer',
              }}
            >
              <img
                src={logo}
                alt='Logo'
                className='h-[38px] w-[37px] tablet:h-[47px] tablet:w-[46px]'
              />
            </button>
            <Typography
              variant='body2'
              sx={{
                color: '#C6C6C6',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className='style-4-mobile phone:style-4 text-[7px] phone:text-[10px] tablet:text-[14px]'>
                {t('date_label')}: {dateTime}
              </span>
            </Typography>
          </div>

          <div className='flex justify-center items-center'>
            <button
              onClick={toggleLanguage}
              className='flex items-center cursor-pointer bg-transparent border-none focus:outline-none'
            >
              <span
                className={`style-4-mobile phone:style-4 text-[7px] phone:text-[10px] tablet:text-[14px] inline ${currentLocale === 'de'
                  ? 'font-bold text-black'
                  : 'text-black/30'
                  }`}
              >
                de
              </span>

              <span className='w-[2px] h-4 bg-black mx-2 inline'></span>

              <span
                className={`style-4-mobile phone:style-4 text-[7px] phone:text-[10px] tablet:text-[14px] inline ${currentLocale === 'en'
                  ? 'font-bold text-black'
                  : 'text-black/30'
                  }`}
              >
                en
              </span>
            </button>

            <IconButton
              edge='end'
              color='inherit'
              aria-label='menu'
              className='md:hidden'
              onClick={toggleMenu}
              sx={{
                color: '#C6C6C6',
                '&:hover': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
            >
              <img
                src={burger}
                alt='Logo'
                className='h-[20px] w-[34px] tablet:h-[29px] tablet:w-[47px]'
              />
            </IconButton>
          </div>
        </Toolbar>
      </div>

      <Drawer
        anchor='top'
        open={isMenuOpen}
        onClose={toggleMenu}
        sx={{
          '& .MuiDrawer-paper': {
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        onClick={toggleMenu}
      >
        <div className='px-4 absolute right-0'>
          <IconButton onClick={toggleMenu}>
            <img
              src={cross}
              alt='cross'
              className='h-[22px] w-[22px] tablet:h-[36px] tablet:w-[36px]'
            />
          </IconButton>
        </div>
        <div className='relative z-20 mt-40 tablet:mt-3'>
          {config?.header_menu
            ? config.header_menu.map((nestedBlok: SbBlokData) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))
            : null}
        </div>
      </Drawer>
    </AppBar>
  );
};

export default Header;
