import { useState } from 'react';
import { StoryblokAsset } from '../../types/StoryblokAsset';
import { pdfjs, Document, Page } from 'react-pdf';
import Button from '../Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type SinglePdfProps = {
  blok: {
    pdf: StoryblokAsset;
  };
};

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


const SinglePdf = ({ blok }: SinglePdfProps) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function nextPage() {
    setPageNumber((v) => ++v);
  }

  function prevPage() {
    setPageNumber((v) => --v);
  }

  return (
    <div className='mb-2 py-32 h-full text-center single-pdf'>
      {/* <header>
        <h1>react-pdf sample page</h1>
        <h1>{pageNumber}</h1>
      </header> */}
      <div className="single-pdf__container">
        <div className="w-[56px]">
          <ArrowBackIcon onClick={prevPage} className={`cursor-pointer !text-[3.5rem] ${pageNumber <= 1 ? '!hidden' : 'block'}`} />
        </div>
        <div className="single-pdf__container__document">
          <Document
            file={blok.pdf.filename}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={false} renderTextLayer={false}
            />
          </Document>
        </div>
        <div className="w-[56px]">
          <ArrowForwardIcon onClick={nextPage} className={`cursor-pointer !text-[3.5rem] ${pageNumber >= (numPages ?? -1) ? '!hidden' : 'block'}`} />
        </div>
      </div>
      <div className="mb-8 !text-center style-1-mobile tablet:style-1">
        Seite: {pageNumber} / {numPages}
      </div>
      <a href={blok.pdf.filename} target="_blank" rel='noreferrer' download>
        <Button variant='contained' label='Download Daily Post' />
      </a>
    </div >
  );
};

export default SinglePdf;