import CtaPost from '../../assets/cta-daily-post.svg';
import CtaLesen from '../../assets/cta-jetzt-lesen.svg';

export const CtaDailyPost = () => {
    return (
        <div className='relative w-[120px] h-[120px] tablet:w-[200px] tablet:h-[200px] bg-transparent'>
            <a href="/dailypost" className='absolute z-50 cursor-pointer w-[90px] tablet:w-[126px] desktop:w-[166px]'>
                <img
                    src={CtaPost}
                    alt='Daily Post'
                    className='w-[90px] tablet:w-[126px] desktop:w-[166px] animate-[imageSwap1_3s_linear_infinite] absolute z-40'
                />
                <img
                    src={CtaLesen}
                    alt='Jetzt lesen'
                    className='w-[90px] tablet:w-[126px] desktop:w-[166px] animate-[imageSwap2_3s_linear_infinite] absolute z-130'
                />
            </a>
            <div className='animate-[blendCTACircle1_1.4s_linear_infinite] absolute w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] rounded-full bg-flieder z-40'></div>
            <div className='animate-[blendCTACircle2_1.4s_linear_infinite] absolute outline outline-flieder/75 w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] bg-transparent rounded-full z-30'></div>
            <div className='animate-[blendCTACircle3_1.4s_linear_infinite] absolute outline outline-flieder/45 outline-offset-[14px] w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] bg-transparent rounded-full z-20'></div>
            <div className='animate-[blendCTACircle4_1.4s_linear_infinite] absolute outline outline-flieder/20 outline-offset-[26px] w-[90px] h-[90px] tablet:w-[126px] tablet:h-[126px] desktop:w-[166px] desktop:h-[166px] rounded-full z-10'></div>
        </div>
    );
};


